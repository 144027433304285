import { dateFormat } from '@common-src/filter';
import { BaseEntityModel } from '@common-src/model/base-model';
import { FormControl, FormControlType, FormControlTextModel } from '@common-src/model/form-control';
import { QueryControl, QueryControlType } from '@common-src/model/query-control';
import { QueryModel } from '@common-src/model/query-model';
import { UserStoreModule } from '@common-src/store/modules/user';

export class RoleEntityModel extends BaseEntityModel {
    @FormControl({
        label: '角色名称',
        type: FormControlType.TEXT,
        required: true
    } as FormControlTextModel)
    name: string = undefined;

    @FormControl({
        label: '描述',
        type: FormControlType.TEXT
    } as FormControlTextModel)
    remark: string = undefined;
    authority: string = 'CORP_ADMIN' || 'PROJECT_ADMIN' || 'USER';
    scopes: any = undefined;
    updateUser: string = undefined;
    updateTime: any = null;

    static getTableColumns() {
        return [
            {
                title: '角色名称',
                dataIndex: 'name',
                scopedSlots: { customRender: 'name' }
            },
            {
                title: '权限范围',
                dataIndex: 'scopes',
                scopedSlots: { customRender: 'scopes' }
            },
            {
                title: '更新人',
                dataIndex: 'updateUser',
                scopedSlots: { customRender: 'updateUser' }
            },
            {
                title: '更新时间',
                dataIndex: 'updateTime',
                customRender: (text) => {
                    return dateFormat(text);
                }
            },
            {
                title: '描述',
                dataIndex: 'remark'
            },
            {
                title: '操作',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' }
            }
        ];
    }

    toModel(json): any {
        super.toModel(json);
        return this;
    }

    toService() {
        const data: any = super.toService();
        data.type = UserStoreModule.CurrentUserPermissionType;
        data.projectId = UserStoreModule.CurrentProjectId;
        return data;
    }
}

export class RoleQueryModel extends QueryModel {
    @QueryControl({
        placeholder: '请输入角色名称',
        type: QueryControlType.TEXT,
        span: 12
    })
    params: string = undefined;

    toService() {
        const data: any = super.toService();
        return data;
    }
}
