
import { RoleEntityModel, RoleQueryModel } from '@common-src/entity-model/role-entity';
import { ICRUDQ } from '@common-src/model/interface';
import { get, post, put, del } from './request';
import PermissionService from './permission';
import CorpService from './corp';
import { PermissionMediaType, PermissionType } from '@common-src/model/enum';
import { PermissionEntityModel } from '@common-src/entity-model/permission-entity';
import SpaceService from '@common-src/service/space-v2';
import { SpaceTreeEntityModel } from '@common-src/entity-model/space-entity';
import DeviceTypeService from '@common-src/service/device-type';

const URL_PATH = `${AUTH_BASE_REQUEST_PATH}/role`;
const LAYOUTMGR_URL_PATH = '/infra-layoutmgr/api';
export const LOCATION_PERMISSION_ID = 'LOCATION';
class RoleService implements ICRUDQ<RoleEntityModel, RoleQueryModel> {
    async create(model: RoleEntityModel): Promise<RoleEntityModel> {
        const url = `${URL_PATH}/add`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async retrieve(modelId: string): Promise<RoleEntityModel> {
        const url = `${URL_PATH}/detail/${modelId}`;
        const res = await get(url);
        return new RoleEntityModel().toModel(res);
    }

    async update(model: RoleEntityModel): Promise<RoleEntityModel> {
        const url = `${URL_PATH}/update`;
        const params = model.toService();
        const res = await post(url, params);
        return res;
    }

    async delete(model: RoleEntityModel): Promise<RoleEntityModel> {
        const url = `${URL_PATH}/${model.id}`;
        const res = await del(url);
        return res;
    }

    async query(query?: RoleQueryModel, page?: number, limit?: number): Promise<any> {
        const url = `${URL_PATH}/list`;
        const params = Object.assign({ page, limit }, query?.toService());
        const res = await post(url, params);
        return _.map(res.items, item => item = new RoleEntityModel().toModel(item));
    }

    async getRolePermissions(id: string) {
        const url = `${URL_PATH}/permission/${id}`;
        const res = await get(url);
        return res;
    }

    async getRolePermissionsV2(id: string, type: string = 'permissions') {
        const url = `${URL_PATH}/permission/v2/${id}`;
        const res = await get(url);
        const data = [..._.get(res, 'permissions'), 'PRODUCT', 'LOCATION'];
        _.forEach(_.get(res, 'dataPermissions'), r => data.push(...r.permissions));
        return data;
    }

    async getRoleLocationPermissions(id: string) {
        const url = `${URL_PATH}/entities/relation/${id}/${LOCATION_PERMISSION_ID}`;
        const res = await get(url);
        return res;
    }

    async getPermissions(modelId: string, permissionType: PermissionType): Promise<Array<PermissionEntityModel | SpaceTreeEntityModel | any>> {
        let permissions: Array<PermissionEntityModel | SpaceTreeEntityModel | any> = null;
        let selectedPermissions: Array<string> = null;
        switch (permissionType) {
            case PermissionType.PLATFORM:
                permissions = await PermissionService.getAllPermissionV2();
                // permissions = await CorpService.getCorpPermissionByType(permissionType);
                selectedPermissions = await CorpService.getCorpPermission(modelId);
                break;

            case PermissionType.CORP:
                permissions = await CorpService.getCorpPermissionByTypeV2(permissionType);
                selectedPermissions = await this.getRolePermissions(modelId);
                break;
            case PermissionType.PROJECT: {
                permissions = await CorpService.getProjectPermissionTree();
                selectedPermissions = await this.getRolePermissionsV2(modelId);
                const devicePermissions = await DeviceTypeService.getProductsWithWritableAttrs({});
                const spacePermissions = await SpaceService.getPermissionLocationTree();
                _.forEach(devicePermissions, r => { if (r.setSelect) { r.setSelect(selectedPermissions); } });
                _.forEach(spacePermissions, r => { if (r.setSelect) { r.setSelect(selectedPermissions); } });
                permissions.push({
                    mediaType: PermissionMediaType.DATA,
                    groups: [new PermissionEntityModel().toModel({
                        id: 'deviceManager',
                        name: '设备控制',
                        permissions: [{
                            id: 'PRODUCT',
                            name: '按设备类型',
                            permissions: devicePermissions,
                            select: true
                        },
                        {
                            id: 'LOCATION',
                            name: '按建筑空间',
                            permissions: spacePermissions,
                            select: true
                        }]
                    })]
                } as any);
                break;
            }
            case PermissionType.PROJECTMANAGER: {
                permissions = await CorpService.getCorpPermissionByTypeV2(PermissionType.PROJECT);
                selectedPermissions = await CorpService.getProjectMangerPermission(modelId);
                break;
            }
        }
        if (permissions && permissions.length > 0) {
            _.forEach(permissions, r => {
                r.groups = _.map(r.groups, item => new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
            });
        }
        if (selectedPermissions && selectedPermissions.length > 0) {
            _.forEach(permissions, item => {
                _.forEach(item.groups, r => {
                    if (r.setSelect) {
                        r.setSelect(selectedPermissions);
                    }
                });
            });
        }

        return permissions;
    }

    async saveRolePrivs(modelId: string, permissions: Array<string>, permissionType: PermissionType, dataPermissions: any): Promise<any> {
        let res = null;
        switch (permissionType) {
            case PermissionType.PLATFORM:
                res = await CorpService.saveCorpPermission(modelId, permissions);
                break;
            case PermissionType.CORP:
                await this.savePermission(modelId, permissions, dataPermissions);
                break;
            case PermissionType.PROJECT:
                await this.savePermission(modelId, permissions, dataPermissions);
                // await this.saveLocationPermission(modelId, _.get(_.find(permissions, item => item.id === LOCATION_PERMISSION_ID), 'children') as any);
                break;
            case PermissionType.PROJECTMANAGER: {
                await this.saveProjectManagerPermission(modelId, permissions);
                break;
            }
        }
        return res;
    }
    async savePermission(id: string, permissions: Array<string>, dataPermissions: any): Promise<any> {
        const url = `${URL_PATH}/permission/add`;
        // const permissions = _.map(_.filter(_.flatMapDepth(permissionList, 'children', 1), item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService());
        const res = await post(url, { roleId: id, permissions, dataPermissions });
        return res;
    }

    async saveProjectManagerPermission(id: string, permissions: Array<string>): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/project/permission/config`;
        const res = await post(url, { projectId: id, permissions });
        return res;
    }
    // async saveLocationPermission(id: string, permissions: Array<string>):Promise<any> {
    //     const url = `${URL_PATH}/entities/relation/${id}/${LOCATION_PERMISSION_ID}`;
    //     const params = _.flatMapDeep(_.map(_.filter(permissionList, (item: SpaceTreeEntityModel) => item.check), (item: SpaceTreeEntityModel) => item.toRoleService()));
    //     const res = await post(url, params);
    //     return res;
    // }

    // 组态权限配置
    async getTopoPermissions(roleId: string): Promise<Array<any>> {
        let permissions: Array<PermissionEntityModel> = null;
        let selectedPermissions: Array<string> = null;
        permissions = await this.getTopoPermissionTree();
        selectedPermissions = await this.getTopoRolePermissions(roleId);
        if (selectedPermissions && selectedPermissions.length > 0) {
            _.forEach(permissions, item => {
                if (item.setTopoSelect) {
                    item.setTopoSelect(selectedPermissions);
                }
            });
        }
        return permissions;
    }

    // 获取组态权限
    async getTopoPermissionTree() {
        const url = `${LAYOUTMGR_URL_PATH}/page/permission/groups`;
        const res = await get(url);
        return _.map(res, item => item = new PermissionEntityModel().toModel(Object.assign({ level: 1 }, item)));
    }

    async getTopoRolePermissions(roleId: string) {
        const url = `${LAYOUTMGR_URL_PATH}/page/permission/role/${roleId}`;
        const res = await get(url);
        return res;
    }

    async saveTopoPermission(id: string, permissions: Array<string>): Promise<any> {
        const url = `${LAYOUTMGR_URL_PATH}/page/permission/add`;
        const res = await post(url, { roleId: id, pages: permissions });
        return res;
    }

    async copyRole(id: string): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/copy/${id}`;
        const res = await post(url);
        return res;
    }

    async batchDeleteRole(ids: any): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/batch/del`;
        const res = await post(url, ids);
        return res;
    }

    async getUsersByRoleId(roleId: string): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/${roleId}/user`;
        const res = await get(url);
        return res;
    }

    async batchDeleteRoleUsers(params: any): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/user/del`;
        const res = await post(url, params);
        return res;
    }

    async batchDeleteRoleOrgs(params: any): Promise<any> {
        const url = `${AUTH_BASE_REQUEST_PATH}/role/org/del`;
        const res = await post(url, params);
        return res;
    }
}

export default new RoleService();
