























































































































































import { Component, Watch } from 'vue-property-decorator';
import DrawerComponent from '@common-src/mixins/drawer-component';
import RoleService, { LOCATION_PERMISSION_ID } from '@common-src/service/role-v2';
import { PermissionType, ResourceType, PermissionMediaType, ViewModeType } from '@common-src/model/enum';
import { PermissionEntityModel } from '@common-src/entity-model/permission-entity';
import { UserStoreModule } from '@common-src/store/modules/user';
import SpaceService from '@common-src/service/space-v2';
import { SpaceTreeEntityModel } from '@common-src/entity-model/space-entity';
import { generateUUID } from '@common-src/utils/base_util';
import DeviceTypeService from '@common-src/service/device-type';
import { flattenTree, findSubTreeById } from '@common-src/utils/tree-util';

@Component
export default class CorpDetailComponent extends DrawerComponent {
    permissionList: Array<PermissionEntityModel | SpaceTreeEntityModel> = null;
    modelId: string;
    permissionType: PermissionType = null;
    loading: boolean = true;
    notUser: boolean = true;
    ResourceType = ResourceType;
    ViewModeType = ViewModeType;
    entrance: string = undefined; // 入口 企业列表进来的权限 默认勾选选择页面的功能权限
    mediaType = null;
    dataPermissionMediaType = 'PRODUCT';
    PermissionMediaType = PermissionMediaType;
    tabsOptions:any = [];
    mode = ViewModeType.VIEW;
    checkedKeys: { checked: Array<any>, halfChecked?: Array<any> } = {
        checked: []
    };

    selectedKeys: Array<string> = [];
    activeKey: string = null;
    searchName: string = null;
    searchKeys: Array<string> = [];
    searchIndex: number = 0;
    expandedKeys: Array<string> = [];
    autoExpandParent: boolean = true;
    flattenDataPermissionTreeData: any = []; // 数据权限平铺数据

    get PermissionTreeData() {
        const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
        if (this.mediaType === PermissionMediaType.DATA) {
            const result = _.find(data, r => r.id === this.dataPermissionMediaType)?.children || [];
            if (this.dataPermissionMediaType === 'PRODUCT' && this.searchName) {
                return _.filter(result, r => r.name.includes(this.searchName));
            }
            return result;
        }
        return data;
    }

    get IsPlatformPermission() {
        return this.permissionType === PermissionType.PLATFORM;
    }

    get PermissionRadioData() {
        return _.find(this.permissionList, (item:any) => this.mediaType === item.mediaType)?.groups || [];
    }

    get DataAllChecked() {
        if (_.isEmpty(this.flattenDataPermissionTreeData)) {
            return false;
        }
        return _.every(this.flattenDataPermissionTreeData, r => _.includes(this.checkedKeys.checked, r.id));
    }

    get RightData() {
        const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
        const fullData = flattenTree(_.flatMap(data, (r:any) => r.children), 'children');
        return _.filter(fullData, r => r.select);
    }

    get RightDataFormatMsg() {
        if (_.isEmpty(this.RightData)) {
            return '';
        }
        const info = _.countBy(this.RightData, 'type');
        return `${info['PRODUCT'] || 0}个产品，${info['FLOOR'] || 0}个建筑空间`;
    }

    drawerRoleOpen(id: string, type: PermissionType, authority: string, entrance?:string) {
        this.entrance = entrance;
        this.modelId = id;
        this.permissionType = type;
        this.drawerVisible = true;
        this.notUser = authority !== 'USER';
        this.mode = ViewModeType.VIEW;
        this.init();
    }

    traverseTree(tree: any, key: string = 'select', checked:boolean = false) {
        const selectedIds = [];
        _.forEach(tree, node => {
            switch (key) {
                case 'select':
                    if (node.select) {
                        selectedIds.push(node.id);
                    }
                    break;
                case 'expand':
                    if (!node.children) {
                        selectedIds.push(node.id);
                    }
                    break;
                case 'search':
                    if (node.name.includes(this.searchName)) {
                        selectedIds.push(node.id);
                    }
                    break;
                case 'check':
                    node.select = checked;
                    if (checked) {
                        selectedIds.push(node.id);
                    }
                    break;
                default:
                    if (this.checkedKeys.checked.includes(node.id)) {
                        node.select = true;
                        selectedIds.push(node);
                    } else {
                        node.select = false;
                    }
                    break;
            }
            if (node.children && node.children.length > 0) {
                const childIds = this.traverseTree(node.children, key, checked);
                selectedIds.push(...childIds);
            }
        });
        return selectedIds;
    }

    initSelectedKeys(data: any) {
        this.checkedKeys.checked = [];
        if (this.notUser && this.mediaType === PermissionMediaType.DATA) {
            const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
            this.checkedKeys.checked = this.traverseTree(_.flatMap(data, (r:any) => r.children), 'check', true);
        } else {
            this.checkedKeys.checked = this.traverseTree(data);
        }
    }

    init() {
        this.loading = true;
        RoleService.getPermissions(this.modelId, this.permissionType)
            .then(res => {
                this.permissionList = res;
                this.tabsOptions = _.map(this.permissionList, (item: any) => {
                    return {
                        key: item.mediaType,
                        name: this.formatPermissionMediaType(item.mediaType)
                    };
                });
                this.mediaType = _.isEmpty(this.tabsOptions) ? null : this.tabsOptions[0].key;
                this.$nextTick(() => {
                    if (this.permissionList && this.permissionList.length > 0) {
                        this.activeKey = this.permissionList[0]['groups'][0].id;
                        this.tabChangeHandler(this.activeKey);
                    }
                });
            })
            .finally(() => {
                this.loading = false;
            });
    }

    onExpand(expandedKeys: any, e: any) {
        this.expandedKeys = expandedKeys;
        this.autoExpandParent = false;
    }

    onSelect(keys:any, e:any) {
        const key = e.node.eventKey;
        this.selectedKeys = [key];
    }

    searchHandler() {
        this.searchIndex = 0;
        if (this.dataPermissionMediaType !== 'PRODUCT') {
            this.searchPremission();
        }
        this.flattenDataPermissionTreeData = flattenTree(this.PermissionTreeData, 'children');
    }

    searchPremission() {
        this.searchIndex = 0;
        this.selectedKeys = [];
        this.searchKeys = this.traverseTree(this.PermissionTreeData, 'search') || [];
        this.expandedKeys = [...this.expandedKeys, ...this.searchKeys];
        if (this.searchKeys.length > 0 && this.searchName) {
            this.operationPremission('next');
        }

        if (_.isEmpty(this.searchName)) {
            if (this.mediaType !== PermissionMediaType.DATA) {
                this.tabChangeHandler(this.activeKey);
                (this.$refs.treeContaint as any).scroll(0, 0);
            }
        }
    }

    operationPremission(type:string) {
        if (this.searchKeys.length === 0) {
            return;
        }
        if (this.mediaType === PermissionMediaType.DATA && this.dataPermissionMediaType === 'PRODUCT') {
            return;
        }
        if (type === 'prev') {
            this.searchIndex--;
            if (this.searchIndex <= 0) {
                this.searchIndex = this.searchKeys.length;
            }
        }
        if (type === 'next') {
            this.searchIndex++;
            if (this.searchIndex > this.searchKeys.length) {
                this.searchIndex = 1;
            }
        }

        this.selectedKeys = [this.searchKeys[this.searchIndex - 1]];
        const el = document.getElementById(`premission-tree-${this.selectedKeys[0]}`);
        if (el) {
            el.scrollIntoView();
        }
    }

    radioChangeHandler(e:any) {
        this.tabChangeHandler(e.target.value);
    }

    tabChangeHandler(key:string) {
        this.activeKey = key;
        this.selectedKeys = [];
        this.checkedKeys.checked = [];
        this.searchName = undefined;
        this.searchKeys = [];
        this.searchIndex = 0;
        this.autoExpandParent = true;
        this.$nextTick(() => {
            if (key === 'deviceManager') {
                void this.dataPermissionChangeHandler('PRODUCT');
            } else {
                this.initSelectedKeys(this.PermissionTreeData);
                this.expandedKeys = this.traverseTree(this.PermissionTreeData, 'expand');
                this.expandedKeys = [];
            }
        });
    }

    mediaTypeChangeHandler(key:any) {
        this.mediaType = key;
        this.activeKey = this.PermissionRadioData ? this.PermissionRadioData[0].id : '';
        this.tabChangeHandler(this.activeKey);
    }

    async dataPermissionChangeHandler(key:any) {
        this.searchName = null;
        this.dataPermissionMediaType = key;
        this.flattenDataPermissionTreeData = flattenTree(this.PermissionTreeData, 'children');
        this.initSelectedKeys(this.PermissionTreeData);
        this.expandedKeys = this.traverseTree(this.PermissionTreeData, 'expand');
    }

    formatPermissionMediaType(val: string) {
        switch (val) {
            case PermissionMediaType.PC:
                return 'Web端';
            case PermissionMediaType.WX:
                return '移动端';
            case PermissionMediaType.UNITY:
                return '大屏';
            case PermissionMediaType.DATA:
                return '数据权限';
        }
    }

    formatCategory(val: string) {
        switch (val) {
            case ResourceType.APPLICATION:
                return '应用';
            case ResourceType.PAGE:
                return '页面';
            case ResourceType.FUNCTION:
                return '功能';
        }
    }

    allCheckChange(e: any) {
        if (this.dataPermissionMediaType === 'PRODUCT') {
            this.traverseTree(this.PermissionTreeData, 'check', e.target.checked);
            const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
            this.checkedKeys.checked = _.map(_.filter(flattenTree(_.flatMap(data, (r:any) => r.children), 'children'), r => r.select), item => item.id);
        } else {
            this.checkedKeys.checked = this.traverseTree(this.PermissionTreeData, 'check', e.target.checked);
        }
    }

    removeAllClick() {
        if (_.isEmpty(this.RightData)) {
            return;
        }
        const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
        this.checkedKeys.checked = this.traverseTree(_.flatMap(data, (r:any) => r.children), 'check', false);
    }

    removeItemClick(id) {
        const index = _.findIndex(this.checkedKeys.checked, item => item === id);
        if (index > -1) {
            this.checkedKeys.checked.splice(index, 1);
        }
        const data = _.find(this.PermissionRadioData, r => r.id === this.activeKey)?.children || [];
        const subData = findSubTreeById(id, data, 'children');
        if (subData) {
            subData.select = false;
        }
    }

    checkHandler(keys:any, e:any) {
        const data = e.node.dataRef;
        this.checkedKeys = keys;
        this.treeItemOnClick(data, e.checked);
        this.traverseTree(this.PermissionTreeData, 'save');
    }

    treeItemOnClick(treeItem: any, checked:boolean) {
        const checkValue = checked;
        this.checkTree(treeItem, checkValue);
        if (checkValue) {
            this.checkParentTree(treeItem.parent, true);
        }
    }

    private checkParentTree(treeParentItem, check: boolean) {
        if (!treeParentItem) {
            return;
        }
        this.checkedKeys.checked = new Array(...new Set([...this.checkedKeys.checked, ...[treeParentItem.id]]));
        this.checkParentTree(treeParentItem.parent, check);
    }

    private checkTree(treeItem, check: boolean) {
        if (check) {
            this.checkedKeys.checked = new Array(...new Set([...this.checkedKeys.checked, ...[treeItem.id]]));
        } else {
            const index = this.checkedKeys.checked.indexOf(treeItem.id);
            if (index > -1) {
                this.checkedKeys.checked.splice(index, 1);
            }
        }
        _.forEach(treeItem.children, child => {
            this.checkTree(child, check);
        });
    }

    save() {
        const dataIdentifier = ['PRODUCT', 'LOCATION'];
        const selectPermissionTree = _.map(_.filter(_.flatMapDepth(_.flatMap(this.permissionList, 'groups'), 'children', 1), item => item.select || item.Indeterminate), (item: PermissionEntityModel) => item.toRoleService());
        const defaultPermission = [];
        const dataPermissions = [];
        _.forEach(selectPermissionTree, r => {
            if (!dataIdentifier.includes(r.id)) {
                defaultPermission.push(r);
            } else {
                dataPermissions.push({
                    type: r.id,
                    permissions: _.filter(this.getSelectPermissions([r]), p => !dataIdentifier.includes(p))
                });
            }
        });
        const permissions = this.getSelectPermissions(defaultPermission);
        return RoleService.saveRolePrivs(this.modelId, permissions as any, this.permissionType, dataPermissions).then(res => {
            if (!UserStoreModule.IsSuperAdmin) {
                this.$eventHub.$emit('MENU_RELOAD');
            }
            this.$emit('dialogOK');
            this.drawerClose();
            // this.mode = ViewModeType.VIEW;
            this.showMessageSuccess('保存权限成功');
        }).catch(err => {
            throw err;
        });
    }

    getSelectPermissions(treeData: Array<any>) {
        const permissions = [];
        _.forEach(treeData, item => {
            permissions.push(item.id);
            if (item.children && item.children.length > 0) {
                permissions.push(...this.getSelectPermissions(item.children));
            }
        });
        return permissions;
    }

    // 判断当前获取节点，是否为特殊对应的项目应用管理
    // appHandler(param: PermissionEntityModel) {
    //     if (param.name === '项目-应用') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
}
